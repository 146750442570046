import React from "react";
import "./../../assets/css/all.css"; 
import "./../../assets/css/Common/footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <p>ⓒ WWW.GORANI.COM</p>
    </footer>
  );
};

export default Footer;
